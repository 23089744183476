<template>
  <div>
    <h3 class="flix-html-h3">{{ $tc('message.customer', 2) }}</h3>
    <calendarBreadcrumbs :link="link" />
    <div class="flix-form-group" v-if="$route.params.id" :key="updateKey">
      <filterData
        :filter="filter"
        :key="filter"
        :setFilter="
          (ret) => {
            filter = ret
            setUpdateKey()
          }
        "
      />
      <flixLoader v-if="!loaded" />

      <crmTable
        :id="$route.params.id"
        :filter="filter"
        :callback="
          () => {
            setLoad()
          }
        "
      />
      <div class="flix-clearfix" />
    </div>
    <div class="flix-form-group">
      <b-flix-alert variant="info">
        <div
          v-html="
            $t('message.customerByUser', {
              icon: '<i class=\'flix-glyphicon icon flix-glyphicon-pen\'></i>'
            })
          "
        ></div>
      </b-flix-alert>
    </div>
    <div class="flix-form-group" v-if="$route.params && $route.params.id">
      <router-link
        :to="{ name: 'dashboardAddCustomer', params: { id: $route.params.id } }"
        class="flix-btn flix-btn-default"
        ><flixIcon :id="'pen'" />
        {{
          $t('message.add', { name: $tc('message.appointments', 1) })
        }}</router-link
      >
    </div>
  </div>
</template>
<script>
export default {
  components: {
    calendarBreadcrumbs() {
      return import('@/components/assets/calendarBreadcrumbs')
    },
    crmTable() {
      return import('@/components/crm/table/')
    },
    filterData() {
      return import('@/components/crm/filterData')
    }
  },
  props: {},
  data() {
    return {
      updateKey: new Date().getTime(),
      loaded: false,
      filter: 'today',
      link: 'dashboardCRM'
    }
  },
  watch: {
    '$route.params.id'() {
      this.loaded = false
      this.setAutoFilter()
      this.updateKey = new Date().getTime()
    }
  },
  methods: {
    setAutoFilter() {
      if (
        this.$store.getters.assistentsByID[this.$route.params.id].type ===
        'eventpicker'
      ) {
        this.filter = 'all'
      } else if (this.filter === 'all') {
        this.filter = 'today'
      }
    },
    setUpdateKey() {
      this.updateKey = new Date().getTime()
    },
    setLoad() {
      this.loaded = true
    }
  },
  mounted() {
    this.loaded = false
    if (this.$getUserVariables().state === 'share') {
      this.link = 'sharesCRM'
    }
  },
  computed: {}
}
</script>
<style lang="sass" scoped></style>
